import { Cushion, FlexContainer, Rectangle, Typography } from '@pitchero/react-ui';
import CLUB_PROP_TYPES from 'lib/prop-types/club';
import { FIXTURE_PROP_TYPE } from 'lib/prop-types/fixture';
import PropTypes from 'prop-types';
import React from 'react';
import Match from './match';

const Matches = ({ matches, title, club, isMobile, showBorder }) => {
  const content = (
    <FlexContainer
      flexDirection="column"
      style={{ width: '100%', backgroundColor: '#fff', height: 'min-content' }}
    >
      <Rectangle fill="#000" component="div">
        <Cushion vertical="small">
          <Typography
            color="white"
            preset="title"
            align="center"
            size={isMobile ? 14 : 16}
            component="div"
          >
            {title}
          </Typography>
        </Cushion>
      </Rectangle>
      <div
        style={{
          border: showBorder ? '1px solid #b8b8b8' : '0',
          borderTop: '0',
          borderRadius: showBorder ? '0 0 8px 8px' : '0',
          overflow: 'hidden',
        }}
      >
        {matches.map((match, index) => (
          <Match
            key={match.id}
            match={match}
            club={club}
            type={match.hasOutcome ? 'result' : 'fixture'}
            isMobile={isMobile}
            isLast={index === matches.length - 1}
          />
        ))}
      </div>
    </FlexContainer>
  );

  if (isMobile) {
    return content;
  }

  return (
    <Rectangle radius="8px" style={{ overflow: 'hidden' }}>
      {content}
    </Rectangle>
  );
};

Matches.propTypes = {
  matches: PropTypes.arrayOf(FIXTURE_PROP_TYPE).isRequired,
  title: PropTypes.string.isRequired,
  club: CLUB_PROP_TYPES.isRequired,
  isMobile: PropTypes.bool.isRequired,
  showBorder: PropTypes.bool,
};

Matches.defaultProps = {
  showBorder: false,
};

export default Matches;
