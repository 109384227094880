import { withTheme } from '@pitchero/react-ui';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import ReactSlider from 'react-slider';
import styled from 'styled-components';
import isServer from 'lib/is-server';
import css from './slider.module.css';

const SliderThumb = styled.div`
  cursor: pointer;
  position: absolute;
  z-index: 100;
  width: 90px;
  height: 12px;
  background: ${(props) => props.color};
  border-radius: 4px;
  border: 1px solid white;
  outline: none;
`;

const Slider = ({ value, min, max, onChange, uiTheme }) => {
  const primary = get(uiTheme, 'colors.primary', '#fff');
  // Check if the browser supports the ResizeObserver API used by the slider
  if (!isServer() && window && 'ResizeObserver' in window) {
    return (
      <ReactSlider
        className={css.slider}
        trackClassName={css.track}
        min={min}
        max={max}
        onChange={onChange}
        value={value}
        renderThumb={(props) => <SliderThumb color={primary} {...props} />}
      />
    );
  }
  return null;
};

Slider.propTypes = {
  value: PropTypes.number.isRequired,
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  uiTheme: PropTypes.shape({}),
};

Slider.defaultProps = {
  uiTheme: {},
};

export default withTheme(Slider);
