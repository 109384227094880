import React from 'react';
import PropTypes from 'prop-types';
import {
  Badge,
  Cushion,
  FlexContainer,
  Grid,
  Image,
  Rectangle,
  Typography,
} from '@pitchero/react-ui';
import { FIXTURE_PROP_TYPE } from 'lib/prop-types/fixture';
import PublishedDate from 'components/published-date';
import ArrowRightIcon from 'icons/ArrowRight';
import CLUB_PROP_TYPES from 'lib/prop-types/club';
import ClubLink from 'components/club-link';

const Match = ({ match, club, type, isLast, isMobile }) => {
  // Renders the row for each side, with logo/badge, team name
  const renderSideRow = ({ side, opponent }) => (
    <Grid columns="36px 1fr" columnGap="5px" alignItems="center">
      {club.logo && side.name !== opponent ? (
        <Image
          src={club.logo}
          alt={club.name}
          height={32}
          width={32}
          transformation="square"
          loading="lazy"
        />
      ) : (
        <Badge primary={side.primaryColour} secondary={side.secondaryColour} size="32" />
      )}
      <Typography preset="document" size={14}>
        {side.name === opponent ? opponent : match.teamName}
      </Typography>
    </Grid>
  );

  // Renders the black score box for a result
  const renderScore = (score) => {
    if (!match.isCancelledOrPostponed && !score) return null;
    return (
      <Rectangle
        fill="#000"
        radius="rounded"
        style={{ maxHeight: '44px', boxSizing: 'border-box' }}
      >
        <Cushion vertical="10px" horizontal="18px">
          <FlexContainer justifyContent="center" alignItems="center" component="div">
            <Typography
              preset="title"
              color="white"
              size={20}
              style={{
                whiteSpace: 'nowrap',
              }}
            >
              {match.isCancelledOrPostponed ? 'P' : score}
            </Typography>
          </FlexContainer>
        </Cushion>
      </Rectangle>
    );
  };

  return (
    <ClubLink
      route={`team.match_centre.${type === 'result' ? 'report' : 'overview'}`}
      params={{ teamId: match.teamId, fixtureId: match.id }}
    >
      <a>
        <FlexContainer
          flexDirection="column"
          style={{
            minHeight: isMobile ? 'none' : '225px',
            backgroundColor: '#fff',
            boxSizing: 'border-box',
            borderBottom: isLast ? 'none' : '1px solid #d9d9d9',
            padding: '16px',
          }}
          component="div"
        >
          <FlexContainer
            justifyContent="space-between"
            alignItems="center"
            style={{ paddingBottom: '20px', gap: '20px' }}
          >
            <Rectangle fill="#e8fde9" radius="rounded">
              <Cushion all="xsmall">
                <Typography color="#0d8211" size={14} weight={600} transform="uppercase">
                  {match.isCancelledOrPostponed
                    ? ` ${match.status} -  ${match.type}`
                    : `${match.division ? match.division : match.type}`}
                </Typography>
              </Cushion>
            </Rectangle>
            <Typography size={14} color="#666" style={{ whiteSpace: 'nowrap' }}>
              <PublishedDate date={match.dateTime} showTime={type === 'fixture'} relative={false} />
            </Typography>
          </FlexContainer>
          <FlexContainer
            flexDirection="column"
            justifyContent="center"
            style={{ height: '100%', margin: 'auto 0' }}
          >
            <Grid columns="1fr auto" rowGap="small">
              <Grid
                rows="1fr 1fr"
                alignItems="center"
                alignContent="center"
                rowGap={isMobile ? '20px' : '30px'}
                style={{ paddingRight: '5px' }}
              >
                {renderSideRow({
                  side: match.homeSide,
                  opponent: match.opponent,
                })}
                {renderSideRow({
                  side: match.awaySide,
                  opponent: match.opponent,
                })}
              </Grid>
              <Grid columns="1fr 20px" alignItems="center" justifyContent="end" columnGap="5px">
                {type === 'fixture' && <Typography preset="body--small" color="#000" />}
                {type === 'result' && (
                  <Grid rows="1fr 1fr" rowGap={isMobile ? '20px' : '30px'} alignItems="center">
                    {renderScore(match.homeSide.score)}
                    {renderScore(match.awaySide.score)}
                  </Grid>
                )}
                <ArrowRightIcon color="gray" size={16} />
              </Grid>
            </Grid>
            {match.location && (
              <Typography
                preset="body--small"
                color="#666"
                style={{ paddingTop: '20px' }}
                component="div"
              >
                {match.location}
              </Typography>
            )}
          </FlexContainer>
        </FlexContainer>
      </a>
    </ClubLink>
  );
};

Match.propTypes = {
  match: FIXTURE_PROP_TYPE.isRequired,
  club: CLUB_PROP_TYPES.isRequired,
  type: PropTypes.oneOf(['result', 'fixture']).isRequired,
  isLast: PropTypes.bool,
  isMobile: PropTypes.bool.isRequired,
};

Match.defaultProps = {
  isLast: false,
};
export default Match;
